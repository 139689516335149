<template>
	<div class="flex flex-row items-center sm:items-end justify-center h-16">
		<nav class="hidden lg:flex flex-row justify-center space-x-2">
			<RouterLink
				v-for="tab in tabs"
				:key="tab.id"
				:class="route.matched.some(({ name }) => name === tab.to?.name) ? 'bg-primary-700 hover:bg-primary-400 text-white' : 'text-gray-500 hover:text-gray-700'"
				:to="tab.to"
				class="whitespace-nowrap py-3 font-medium text-sm hover:bg-primary-100 px-8 rounded-tr-md rounded-tl-md"
			>
				{{ tab.name }}
			</RouterLink>
		</nav>
		<nav class="flex lg:hidden w-full h-full items-center justify-center">
			<div class="flex flex-row w-full h-full items-center justify-center">
				<div class="w-48 sm:w-64">
					<FormKit v-model="selectedTab" type="dropdown" :options="getNavigationOptions" :ignore="true" :popover="true" selector-class="min-h-12" inner-class="$remove:mb-1" listitem-class="h-12" />
				</div>
			</div>
		</nav>
	</div>
</template>

<script setup lang="ts">
	import { computed, defineProps, ref, watch } from 'vue';
	import { HeaderNavigationTab } from '@/types/layout';
	import { useRoute, useRouter } from 'vue-router';

	const props = defineProps<{
		tabs: HeaderNavigationTab[];
	}>();

	const selectedTab = ref(props.tabs[0]);
	const route = useRoute();
	const router = useRouter();

	const getNavigationOptions = computed(() => {
		const options = [];
		for (const tab of props.tabs) {
			options.push({
				label: tab.name,
				value: tab,
			});
		}
		return options;
	});

	for (const tab of props.tabs) {
		if (route.name === tab.to?.name) {
			selectedTab.value = tab;
		}
	}

	watch(
		() => route.name,
		(newValue) => {
			if (newValue) {
				for (const tab of props.tabs) {
					if (newValue === tab.to?.name) {
						selectedTab.value = tab;
					}
				}
			}
		},
	);

	watch(
		() => selectedTab.value,
		(newValue) => {
			if (newValue.to) {
				router.push(newValue.to);
			}
		},
	);
</script>
